export default function () {
    // headerScroll
    var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var target = "header.top-a > .wrap"
        var navbarHeight = $("header.top-a > .wrap").outerHeight();
        console.log("navbarHeight: " + navbarHeight);
        $(window).scroll(function(event){
            didScroll = true;

        });
        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);
        function hasScrolled() {
            var st = $(window).scrollTop();
            // Make sure they scroll more than delta
            if(Math.abs(lastScrollTop - st) <= delta)
                return;
            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > navbarHeight){
                // Scroll Down
                $("header.top-a").removeClass('nav-down').addClass('nav-up');
                $("header .corner").removeClass("open");
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                    $("header.top-a").removeClass('nav-up').addClass('nav-down');
                    $("header .corner").removeClass("open");
                }
            }

            lastScrollTop = st;
        }
        $("#emerge-header ").click(function() {
            $("header.top-a").removeClass('nav-up').addClass('nav-down');
            $("header .corner").addClass("open");
        });
}