import timeCheck from "./timeCheck";
import reset from "./reset";

export default function () {
     //init to test for local storage
     if (localStorage.getItem("blogReminder") === null) {
        const current = new Date();
        localStorage.setItem("blogReminder", current);
    }
     // check to see if it's the blog page
    // if you want to test this, here's a quick reset to display the message, use in console
    // const oldDate = new Date(); oldDate.setFullYear(2020, 0, 1); localStorage.setItem("blogReminder", oldDate);

    const bodyHasClass = document.body.classList.contains( 'single-post');

    // make sure the that date is set, on blog and fails 24 hours since last visit
    if (localStorage.getItem("blogReminder") !== null && bodyHasClass && !timeCheck()) {

        let showPopup = true;
        $(window).scroll(function (event) { 
            let scrollPosition = $(window).scrollTop(); 
            let pageHeight = $(document).height() ;
            let whereAmI = scrollPosition / pageHeight;
            if (whereAmI > .5 && showPopup ) {
            $('body').addClass("show-blog-popup");
                showPopup = false;
            }
        });

        $(".blog-reminder-popup .close").click(function() {
             reset();
            showPopup = false;
        });
        
        const backgroundElem = document.getElementsByClassName("blog-reminder-popup")
        backgroundElem[0].addEventListener('click', function(e) {
            if (e.target === this) {
                reset();
                showPopup = false;
            }
        });
        
        function completeSubmit(){
            //hide form and set the date
            $('body').removeClass("show-blog-popup");
            localStorage.setItem("blogReminder", current);
        }

        $('.blog-reminder-popup [type="submit"]').click(function() {
            let current = new Date();
            current.setFullYear(current.getFullYear() + 5);
            //console.log(current);
            setTimeout(() => {
            if ($(".blog-reminder-popup").find(".gform_confirmation_wrapper ").length > 0){  
                completeSubmit();
            }
            }, "3000");
            setTimeout(() => {
            if ($(".blog-reminder-popup").find(".gform_confirmation_wrapper ").length > 0){  
                completeSubmit();
            }
            }, "6000")
        });
    }
}