"use strict";
import approachMovie from "../components-es6/approachMovie";
import blogFilter from "../components-es6/blogFilter";
import gmailFocus from "../components-es6/gmailFocus";
import headerScroll from "../components-es6/headerScroll";
import insightsPopup from "../components-es6/insightsPopup/insightsPopup";
import maskEmail from "../components-es6/maskEmail";
import menuInit from "../components-es6/menu/menu";
import responsiveVideos from "../components-es6/responsiveVideo";
import serviceShare from "../components-es6/serviceShare";
// import showHoverState from "../components-es6/showHoverState";
import slideshow from "../components-es6/slideshow";
import matchHeightLoad from "../components-es6/matchHeightLoad";


menuInit();// load me first, handles menu functions (see for details)

approachMovie(); // special case for Approach page
blogFilter(); //mobile blog filter show/hide
gmailFocus();  // gform_wrapper found in gravity forms
headerScroll(); // used to hide/show menu
insightsPopup(); // popup on the insights single post
maskEmail(); //unclear if still used
matchHeightLoad(); //depreciated, loads jQuery MatchHeight if needed
responsiveVideos(); //fitVid.js inits
serviceShare(); //blog share button behavior
// showHoverState(); looks to be unnecessary
slideshow(); //slick.js init for about page (see for details)

//console.log('hello world');