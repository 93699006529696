export default function() {
    $(".share li a").click(function() {
        //console.log("Social media")
        var service = $(this).data("service"),
          url = $(this).data("url"),
          title = $(this).data("title"),
          descr = $(this).data("descr"),
          image = $(this).data("image"),
          winWidth = $(this).data("winwidth"),
          winHeight = $(this).data("winheight"),
          winTop = screen.height / 2 - winHeight / 2,
          winLeft = screen.width / 2 - winWidth / 2;
    
        if (service == "facebook") {
          window.open(
            "http://www.facebook.com/sharer.php?s=100&p[title]=" +
              title +
              "&p[summary]=" +
              descr +
              "&p[url]=" +
              url +
              "&p[images][0]=" +
              image,
            "sharer",
            "top=" +
              winTop +
              ",left=" +
              winLeft +
              ",toolbar=0,status=0,width=" +
              winWidth +
              ",height=" +
              winHeight
          );
        }
    
        if (service == "google") {
          window.open(
            "https://plus.google.com/share?mini=true&url=" +
              url +
              "&title=" +
              title,
            "sharer",
            "top=" +
              winTop +
              ",left=" +
              winLeft +
              ",toolbar=0,status=0,width=" +
              winWidth +
              ",height=" +
              winHeight
          );
        }
    
        if (service == "linkedin") {
          window.open(
            "http://www.linkedin.com/shareArticle?url=" +
              url +
              "&title=" +
              title,
            "sharer",
            "top=" +
              winTop +
              ",left=" +
              winLeft +
              ",toolbar=0,status=0,width=" +
              winWidth +
              ",height=" +
              winHeight
          );
        }
    
        if (service == "twitter") {
          window.open(
            url,
            null,
            "height=449,width=600,status=yes,toolbar=no,menubar=no,location=no"
          );
        }
      });
}

