export default function () {
  //blogFilter
  $(".filter").click(function() {
    $(".filter-area").show();
    $(".close").show();
    $(".filter").hide();
  });
  $(".close").click(function() {
    $(".close").hide();
    $(".filter").show();
    $(".filter-area").hide();
  });
}

