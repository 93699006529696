// Aria Functions for resizing and toggle states
import toggleMenuAria from "./toggleMenuAria";
import toggleMenuAriaResize from "./toggleMenuAriaResize";


export default function () {
    // Init Aria states
    toggleMenuAriaResize();

     //hoverMenu
     $(".menu-item").hover(
      function() {
          $("body").addClass("unhightlight");
      }, function() {
          $("body").removeClass("unhightlight");
      }
      )

    //set click behavior
    $(".hamburger-or-veggie-burger-we-are-in-pdx-after-all").click(function(
        event
      ) {
        $("header .corner").toggleClass("open");
        setTimeout(function() {
          $("header .corner").toggleClass("delay");
        }, 900);
        toggleMenuAria();
      });
      $(".hamburger-or-veggie-burger-we-are-in-pdx-after-all").keypress(
        function(e) {
          if (e.which == 13) {
            $("header .corner").toggleClass("open");
            toggleMenuAria();
          }
        }
      );
}