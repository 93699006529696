export default function () {
    const matchHeightLoad = document.querySelector('.my-class');

    if (matchHeightLoad) {
    // The class exists on the page
        const script = document.createElement("script");
        // Add script content
        script.src = "/assets/js/libs/jQuery.matchHeight.0.7.2.min.js";
        // Append
        document.head.appendChild(script);
    } 
}