export default function () {
    const doesClassExist = document.getElementsByClassName('gform_wrapper');
        if (doesClassExist.length > 0) {
        const items = ".gform_wrapper input, .gform_wrapper select, .gform_wrapper textarea"
        $(document).on("focus", items, function () {
            $(this).parent().parent().addClass("focused");
        })
        $(document).on("focusout", items, function () {
            $(this).parent().parent().removeClass("focused");
        })
    }
}

