export default function() {
   /****************************************************
    Takes jim/at/jimbo.com in markup and converts to
    jim@jimbo.com to protect email address from bots
    ****************************************************/
    $("a[href^='mailto:']").each(function() {
      var mail = $(this)
          .attr("href")
          .replace("mailto:", ""),
        replaced = mail.replace("/at/", "@");
      $(this).attr("href", "mailto:" + replaced);

      if ($(this).text() === mail) {
        $(this).text(replaced);
      }
    });
}