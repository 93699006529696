export default function () {
    if (localStorage.getItem("blogReminder") !== null) {

        const day = 24
    
        //get the date stored and parse
        const storedDate = localStorage.getItem("blogReminder");
        const then = Date.parse(storedDate);
        let now = new Date();
        now = Date.parse(now);         
    
        const msBetweenDates = now - then;
        // 👇️ convert ms to hours                  min  sec   ms
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
        //console.log(hoursBetweenDates);
    
        
        const days = 5 * day;
        if (hoursBetweenDates < days) {
          return true
        } else {
          return false
        }
      }
}

