export default function () {
  // works on the about page

  $(".youtubevideo .overlay").click(function() {
    const movie = $(this).parent().parent(); //get only the current movie

    $(movie).addClass("show-video");
    $("header.top-a").removeClass('nav-down').addClass('nav-up');
    $("header .corner").removeClass("open");
    $(movie).find(".fullscreenbib").append("<a id='close'><img src='/wp-content/themes/emerge/assets/images-2017/icons/close.svg'></a>");
    var test = $(movie).find(".fullscreenbib .video iframe")[0].src;
    $(movie).find(".fullscreenbib .video iframe").css('max-height', '100vh');
    if (test.indexOf("?") != -1) {
      $(movie).find(".fullscreenbib .video iframe")[0].src += "&autoplay=1";
    } else {
     $(movie).find(".fullscreenbib .video iframe")[0].src += "?&autoplay=1";
    }
    setTimeout(function() {
      $(movie).addClass("opacity1");
    }, 50);

    setTimeout(function() {
      $("html").addClass("hide-overlay");
    }, 1000);

    $(document).keyup(function(e) {
         if (e.key === "Escape") { // escape key maps to keycode `27`
            // <DO YOUR WORK HERE>
          if ($('.show-video')[0]) {
            $(".fullscreenbib").click();
          }
        }
    });
  });
  $(".fullscreenbib").click(function() {
    var movie2 = $(this).parent();
    $(movie2).removeClass("opacity1");

    var str = $(movie2).find(".fullscreenbib .video iframe")[0].src
    var res = str.replace("?&autoplay=1", "");
    $(movie2).find(".fullscreenbib .video iframe")[0].src = res;
    setTimeout(function() {
      $(movie2).removeClass("show-video");
    }, 500);
  });
}