/* Note: this depends on the script.php's load_scripts(), as it is no longer globally loaded*/

export default function() {
    // slide slider 

    const doesClassExist = document.getElementsByClassName('values-a');
    if (doesClassExist.length > 0) {
    // The class exists on the page
    console.log("slideshow");
        $(".values-a .items").slick({
        //slide: '.item',
              swipeToSlide: true,
              speed: 120,
              infinite: false,
              arrows: false,
              touchThreshold: 6,
        fade: true,
        //vertical: true,
              dots: true,
        customPaging : function(slider, i) {
          var number = $('[data-number]');
          return '<span>' + $(number[i]).data('number') + '</span><div class="rectangle"></div>';
         },
      })

    }
}